// Network Configuration
export const NetworkContextName = "Rinkeby Test Network";
export const ACTIVE_NETWORK = 4;
export const RPC_URL =
  "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
export const MarketplaceContract = "0x0B216A6B8D55c95F9559b58294bD7525de68f758";
export const AdminWalletAddress = "0xA93647C91133454fB265821334083375b12F06e5";
export const baseURI =
  "https://ipfs.io/ipfs/QmdP6VwefyuVTz3k7n2AcPv16HxJ3fz3zN23vAkJY9S6ST";

// Time Pending
export const countDownDate = new Date("Nov 19, 2021 08:00:00 PST").getTime();
export const countDownDate1 = countDownDate.toLocaleString("en-US", {
  timeZone: "America/New_York",
});
