import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiToolbar:{
      regular :{
      minHeight: "0px !important",
      "@media (max-width: 1024px)":{
        minHeight: "70px !important",
      },
  },
},
MuiAppBar:{
  positionFixed:{
    backgroundColor: "#272a30",
  },
},
    MuiSelect: {
      outlined: {
        paddingTop: "7px",
      },
    },
    MuiSlider: {
      root: {
        color: " #ffffff",
        height: "10px",
      },
      track: {
        height: "10px",
        borderRadius: "50px!important",
        backgroundColor: "#e21ae7 !important",
      },
      rail: {
        height: "10px",
        borderRadius: "50px!important",
        backgroundColor: "#e21ae7 !important",
        backgroundColor: "#fff",
        opacity: 1,
      },
      thumb: {
        width: "20px",
        height: "20px",
      },
      valueLabel: {
        left: "calc(-50% - -3px)",
      },
      mark: {
        display: "none",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "2px solid rgb(0 0 0)",
        backgroundColor: "#272a30",
        color: "#fff !important",
        padding: "5px 16px",
      },
    },
    PrivateValueLabel: {
      label: {
        color: " #000",
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiList: {
      padding: {
        padding: "10px",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(0 255 171) !important",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#fff",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#ffff",
        },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        font: "caption",
        color: "#fff",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#040404",
        fontSize: "14px",
        backgroundColor: "#00FFAB",
        padding: "5px 19px",
        textTransform: "uppercase",
        borderRadius: "100px",
        fontWeight: "bold",
        "@media (max-width: 767px)":{
          fontSize: "10px !important",
          height: "30px",
        },
        "&:hover": {
          backgroundColor: "#fff",
          color: "#000",
        },
      },

      containedPrimary: {
        backgroundColor: "#fff",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#000",
        fontSize: "15px",
        height: "45px",
        lineHeight: " 21px",
        marginRight: "10px",
        borderRadius: "100px",
        textTransform: "uppercase",
        fontWeight: "bold",
        "@media (max-width: 767px)":{
          fontSize: "10px !important",
          height: "30px",
        },
        "&:hover": {
          backgroundColor: "#00FFAB",
          color: "#000",
        },
      },
      containedSizeLarge: {
        padding: "14px 22px 7px",
        '@media (max-width: 767px)' :{
          padding: "9px 14px 3px",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#f30065",
        fontWeight: 600,
        padding: "11px 22px 7px",
        "&:hover": {
          backgroundColor: "#000",
          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Whyte, sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
