import React from "react";
import {
  Grid,
  Box,
  Container,
  makeStyles,
  Button,
  Link,
} from "@material-ui/core";
import { FaTwitter } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    borderTop: "2px solid #00FFAB",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& img": {
      [theme.breakpoints.down("xs")]: {
    width:"100%",
      },
    },
    "& p": {
      marginBottom: "0px",
      paddingBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
      color: "#cecece",
    },
    "& svg": {
      color: "#fff",
      fontSize: "30px",
      marginLeft: "15px",
      [theme.breakpoints.down("xs")]:{
        fontSize:" 20px",
        marginLeft: "10px",
      },
      "&:hover": {
        color: "#00ffab",
        textDecoration:"none",
      },
    },
  },
  centerText:{
    textAlign:"center",
  },
  rightText:{
    textAlign:"right",
    [theme.breakpoints.down("xs")]: {
      textAlign:"center",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={3} sm={4}>
            <img alt="" src="images/logo.png" />
          </Grid>
          <Grid item xs={6} sm={4} align="center" className={classes.centerText}>
            <Button variant="contained" size="large" color="primary" target="_blank" href="https://RiseAngle.com" className={classes.buttonright}>
            Official Website
            </Button>
          </Grid>
          <Grid item xs={3} sm={4} className={classes.rightText}>
            <Link target="_blank" href="https://discord.com/invite/KcubwDTu3H"> <SiDiscord /></Link>
            <Link target="_blank" href="https://twitter.com/riseangleinc"> <FaTwitter /></Link>
          </Grid>
        </Grid>
      </Container>
      <p> Copyright @2021</p>
    </Box>
  );
}
